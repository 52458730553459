export const baseDocumentTypes = {
  0: 'Other',
};

export const staffRequiredDocumentTypes = {
  230: 'Signed Curriculum Confidentiality agreement',
  235: 'Signed Staff Handbook Acknowledgment form',
  240: 'Signed Contract',
  245: 'Highest degree',
  246: 'Kipinä Academic Training Certificate (KAT)',
  247: 'Character Strength Certificate',
};

export const staffDocumentTypes = {
  121: 'Passport',
  200: 'Diploma Masters Educationo',
  205: 'Diploma Masters Other',
  210: 'Bachelors Masters Other',
  215: 'Bachelors Masters Other',
  220: 'Post Graduate Certificate Education',
  225: 'Post Graduate Certificate Other',
  ...staffRequiredDocumentTypes,
};

export const kidDocumentTypes = {
  10: 'Passport',
  100: 'Birth Certificate',
  110: 'Vaccination and Immunisation Record',
  120: 'Signed in Application From / Contact',
};
export const schoolDocumentsTypes = {
  310: 'Public Liability Insurance Certificate',
  311: 'Building & Contents Insurance Certificate',
  312: 'Local Authority Approval',
  313: 'Local Authority Education License',
  314: 'Food Hygiene License',
  315: 'Transportation License',
};

export const partnerDocumentTypes = {
  410: 'Franchise Agreement',
  411: 'Certificate of Incorporation',
  412: 'Passport',
};

export const staffDocumentTypesList = Object.entries({
  ...baseDocumentTypes,
  ...staffDocumentTypes,
  ...staffRequiredDocumentTypes,
}).map(([key, val]) => ({
  label: val,
  value: parseInt(key),
}));

export const staffRequiredDocumentTypesList = Object.entries({
  ...staffRequiredDocumentTypes,
}).map(([key, val]) => ({
  label: val,
  value: parseInt(key),
}));

export const kidDocumentTypesList = Object.entries({
  ...baseDocumentTypes,
  ...kidDocumentTypes,
}).map(([key, val]) => ({
  label: val,
  value: parseInt(key),
}));

export const schoolDocumentTypesList = Object.entries({
  ...baseDocumentTypes,
  ...schoolDocumentsTypes,
}).map(([key, val]) => ({
  label: val,
  value: parseInt(key),
}));

export const partnerDocumentTypesList = Object.entries({
  ...baseDocumentTypes,
  ...partnerDocumentTypes,
}).map(([key, val]) => ({
  label: val,
  value: parseInt(key),
}));

export const allDocumentTypes = {
  ...baseDocumentTypes,
  ...schoolDocumentsTypes,
  ...kidDocumentTypes,
  ...staffDocumentTypes,
  ...partnerDocumentTypes,
};

export const allDocumentTypesFilter = Object.entries({
  ...allDocumentTypes,
}).map(([key, val]) => ({
  text: val,
  value: parseInt(key),
}));

export const allDocumentTypesList = Object.entries({
  ...allDocumentTypes,
}).map(([key, val]) => ({
  label: val,
  value: parseInt(key),
}));
